import React from 'react';
import OrganizationSchema from './organization';
import BreadCrumbSchema from './BreadCrumb';
import ResidenceSchema from './Residence';
import FAQPageSchema from './FAQ';
import ArticleSchema from './Article';

export enum SCHEMA_TYPES {
  organization = 'organization',
  breadcrumb = 'breadcrumb',
  residence = 'residence',
  faqs = 'faqs',
  compound_article = 'compound',
  area_article = 'area',
  developer_article = 'developer'
}

function SchemaBuilder(props: { pageProps: any; locale?: string }) {
  return (
    <>
      {props.pageProps?.schemas?.map((schema: SCHEMA_TYPES) => {
        switch (schema) {
          case SCHEMA_TYPES.organization:
            return <OrganizationSchema key={schema} locale={props.locale} />;
          case SCHEMA_TYPES.breadcrumb:
            return (
              <BreadCrumbSchema
                key={schema}
                locale={props.locale}
                pageProps={props.pageProps}
              />
            );
          case SCHEMA_TYPES.residence:
            return (
              <ResidenceSchema
                key={schema}
                pageProps={props.pageProps}
                locale={props.locale}
              />
            );
          case SCHEMA_TYPES.faqs:
            return <FAQPageSchema key={schema} pageProps={props.pageProps} />;
          case SCHEMA_TYPES.compound_article:
          case SCHEMA_TYPES.developer_article:
          case SCHEMA_TYPES.area_article:
            return (
              <ArticleSchema
                key={schema}
                schemaType={schema}
                pageProps={props.pageProps}
                locale={props.locale}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
}

export default SchemaBuilder;
