import { Article } from 'schema-dts';
import { JsonLd } from './JsonLD';
import { SeoUtils } from '../../../new-lib/utilities/seo-utils';
import { SCHEMA_TYPES } from './SchemaBuilder';

function getEntityName(entity: any, schemaType: SCHEMA_TYPES, locale: string) {
  switch (schemaType) {
    case SCHEMA_TYPES.compound_article:
      return locale == 'ar'
        ? `${entity.name} في ${entity.area?.name} من ${entity.developer?.name}`
        : `${entity.name} in ${entity.area?.name} by ${entity.developer?.name}`;
    case SCHEMA_TYPES.area_article:
    case SCHEMA_TYPES.developer_article:
      return entity.name;
    default:
      return '';
  }
}

function getEntityImage(entity: any, schemaType: SCHEMA_TYPES) {
  switch (schemaType) {
    case SCHEMA_TYPES.compound_article:
      return entity.cover_image_path;
    case SCHEMA_TYPES.area_article:
      return entity.image.url;
    case SCHEMA_TYPES.developer_article:
      return entity.logo_path;
    default:
      return '';
  }
}

function getPublisher(locale: string) {
  const websiteUrl = `${process.env.NEXT_PUBLIC_HOST}${locale == 'ar' ? '/ar' : ''}`;

  return {
    '@type': 'Organization',
    '@id': websiteUrl,
    name: 'Nawy',
    logo: {
      '@type': 'ImageObject',
      '@id': 'https://www.nawy.com/#logo',
      url: 'https://www.nawy.com/assets/icons/common/nawy.svg',
      contentUrl: 'https://www.nawy.com/assets/icons/common/nawy.svg',
      caption: 'Nawy ',
      inLanguage: locale
    }
  };
}

function getKeywords(entity: any, schemaType: SCHEMA_TYPES, locale: string) {
  switch (schemaType) {
    case SCHEMA_TYPES.compound_article:
      return entity.name;
    case SCHEMA_TYPES.area_article:
    case SCHEMA_TYPES.developer_article:
      return locale == 'ar'
        ? `${entity.name} - كمبوندات ${entity.name}`
        : `${entity.name} - Compounds in ${entity.name}`;
    default:
      return '';
  }
}

function getMetaTitle(entity: any, schemaType: SCHEMA_TYPES, locale: string) {
  switch (schemaType) {
    case SCHEMA_TYPES.compound_article:
      return SeoUtils.getCompoundMetaTitle(entity, locale);
    case SCHEMA_TYPES.area_article:
      return SeoUtils.getAreaMetaTitle(entity, locale);
    case SCHEMA_TYPES.developer_article:
      return SeoUtils.getDeveloperMetaTitle(entity, locale);
    default:
      return '';
  }
}
function getMetaDescription(
  entity: any,
  schemaType: SCHEMA_TYPES,
  locale: string
) {
  switch (schemaType) {
    case SCHEMA_TYPES.compound_article:
      return SeoUtils.getCompoundMetaDescription(entity, locale);
    case SCHEMA_TYPES.area_article:
      return SeoUtils.getAreaMetaDescription(entity, locale);
    case SCHEMA_TYPES.developer_article:
      return SeoUtils.getDeveloperMetaDescription(entity, locale);
    default:
      return '';
  }
}

function createArticleSchema(
  entity: any,
  schemaType: SCHEMA_TYPES,
  locale: string
) {
  const isArabic = locale === 'ar';
  const websiteUrl = `${process.env.NEXT_PUBLIC_HOST}${locale == 'ar' ? '/ar' : ''}`;
  const companyName = isArabic ? 'ناوي للعقارات' : 'Nawy Real Estate';
  const slug = isArabic ? entity.all_slugs?.ar : entity.all_slugs?.en;
  const entityUrl = `${process.env.NEXT_PUBLIC_HOST}${locale == 'ar' ? '/ar' : ''}/${schemaType}/${slug}`;
  const entityName = getEntityName(entity, schemaType, locale);
  const image = getEntityImage(entity, schemaType);

  const article: Article = {
    '@type': 'Article',
    headline: getMetaTitle(entity, schemaType, locale),
    description: getMetaDescription(entity, schemaType, locale),
    keywords: getKeywords(entity, schemaType, locale),
    name: entityName,
    inLanguage: locale,
    author: {
      '@type': 'Organization',
      name: 'Nawy',
      url: websiteUrl
    },
    publisher: getPublisher(locale),
    image: {
      '@type': 'ImageObject',
      '@id': image,
      url: image,
      contentUrl: image,
      width: '1000',
      height: '563',
      caption: entity.name,
      inLanguage: locale
    },
    mainEntityOfPage: {
      '@type': 'ItemPage',
      '@id': entityUrl,
      url: entityUrl,
      name: entityName,
      inLanguage: locale
    },
    isPartOf: {
      '@type': 'WebSite',
      '@id': websiteUrl,
      url: websiteUrl,
      name: companyName,
      alternateName: isArabic ? 'ناوي' : 'Nawy',
      inLanguage: locale
    }
  };

  return article;
}

function ArticleSchema(props: {
  pageProps: any;
  schemaType: SCHEMA_TYPES;
  locale?: string;
}) {
  const { compound, area, developer } = props.pageProps;
  const { schemaType, locale } = props;
  switch (schemaType) {
    case SCHEMA_TYPES.compound_article: {
      if (!compound) return <></>;
      return JsonLd<Article>('article-schema', {
        '@context': 'https://schema.org',
        ...createArticleSchema(compound, schemaType, locale as string)
      });
    }
    case SCHEMA_TYPES.area_article: {
      if (!area) return <></>;
      return JsonLd<Article>('article-schema', {
        '@context': 'https://schema.org',
        ...createArticleSchema(area, schemaType, locale as string)
      });
    }
    case SCHEMA_TYPES.developer_article: {
      if (!developer) return <></>;
      return JsonLd<Article>('article-schema', {
        '@context': 'https://schema.org',
        ...createArticleSchema(developer, schemaType, locale as string)
      });
    }
    default:
      return <></>;
  }
}
export default ArticleSchema;
