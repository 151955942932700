import {
  getClickWhatsAppEndpoint,
  getFilterOptionsEndpoint,
  getSearchLocationsEndpoint,
  getSearchResultsEndpoint,
  getSearchCompoundEndpoint,
  getSearchDeveloperEndpoint,
  getSearchAreaEndpoint,
  getPhoneLeadInfoEndPoint,
  getAvailablePropertyTypesEndpoint,
  getCompoundEndpoint,
  getPropertyEndpoint,
  getCompoundsCachedEndpoint,
  getCompoundsEndpoint,
  getAreaEndpoint,
  getDeveloperEndpoint,
  getDevelopersEndpoint,
  getTrackActivityEndpoint,
  getStatisticsEndpoint,
  getAreasCachedEndpoint,
  submitPropertyMonitorFormEndpoint,
  getFooterLinksEndpoint,
  getAreasEndpoint,
  getRecentSearchEndpoint,
  getLaunchesAreasEndpoint,
  getPropertyTypesEndpoint,
  getOffersEndpoint,
  getNewLandingPageEndpoint,
  resaleAdditionalDataEndpoint,
  getAreaFuzzyEndpoint,
  getOfferDevelopersEndpoint,
  getOfferAreasEndpoint,
  getNewOffersEndpoint,
  getOffersFilterOptionsEndpoint,
  getAgentVerification,
  getAffordabilityEndpoint,
  getCompoundSummaryEndpoint,
  getDynamnicBannersEndpoint,
  getAreaSummaryEndpoint
} from './endpoints';
import { newApi, nextServerApi, oldApi } from './api.instance';
import { mapOptionsToNewForm } from '../../helpers/search-options/common';
import { Banner } from '../../features/home/dtos';
import { GenericAbortSignal } from 'axios';
import { NewTopCompoundsDTO } from '../dtos/landing-pages/top-compounds.dto';

export const fetchAgentInfo = async (phone: number) => {
  const END_POINT = getAgentVerification();
  return newApi().post(END_POINT, { phoneNumber: `+2${phone}` });
};

export const clickWhatsapp = async (
  body: any,
  language: string | undefined
) => {
  const END_POINT = getClickWhatsAppEndpoint(language);
  return oldApi().post(END_POINT, body);
};

export const fetchSearchResults = async (
  body: any,
  language?: string,
  options?: { signal?: GenericAbortSignal }
) => {
  const END_POINT = getSearchResultsEndpoint(language);
  return oldApi().post(END_POINT, body, { signal: options?.signal });
};

export const fetchCompoundSearchResults = async (
  body: any,
  language?: string
) => {
  const END_POINT = getSearchResultsEndpoint(language);
  return oldApi().post(END_POINT, body);
};

export const getFilterOptions = async (language?: string) => {
  const END_POINT = getFilterOptionsEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => mapOptionsToNewForm(data));
};

export const getSearchLocations = async (
  term: string,
  language?: string,
  map?: boolean,
  exclude?: string[],
  options?: {
    signal?: GenericAbortSignal;
  }
): Promise<any> => {
  const END_POINT = getSearchLocationsEndpoint(term, language, map, exclude);
  return oldApi()
    .get(END_POINT, { signal: options?.signal })
    .then(({ data }) => data);
};

export const getAreaFuzzy = async (term: string, language?: string) => {
  const END_POINT = getAreaFuzzyEndpoint(term, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getSearchCompound = async (id: number, language?: string) => {
  const END_POINT = getSearchCompoundEndpoint(id, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getSearchDeveloper = async (id: number, language?: string) => {
  const END_POINT = getSearchDeveloperEndpoint(id, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getSearchArea = async (id: number, language?: string) => {
  const END_POINT = getSearchAreaEndpoint(id, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const phoneLeadsInfo = async (body: any, language?: string) => {
  const END_POINT = getPhoneLeadInfoEndPoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getCompound = async (
  slug: string,
  language?: string,
  detail?: string
) => {
  const END_POINT = getCompoundEndpoint(slug, language, detail);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getCompoundSummary = async (id: number, language?: string) => {
  const END_POINT = getCompoundSummaryEndpoint(id);
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getCompoundsCached = async (
  language?: string,
  moveNowOnly?: boolean
) => {
  const END_POINT = getCompoundsCachedEndpoint(language, moveNowOnly);
  return nextServerApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getAreasCached = async (
  language?: string,
  moveNowOnly?: boolean
) => {
  const END_POINT = getAreasCachedEndpoint(language, moveNowOnly);
  return nextServerApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getCompounds = async (
  language?: string,
  moveNowOnly?: boolean,
  body?: any
) => {
  const END_POINT = getCompoundsEndpoint(language, moveNowOnly, body);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getFooterLinks = async (language?: string, source?: string) => {
  const END_POINT = getFooterLinksEndpoint();
  return newApi({ ssrLocale: language, source })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getAvailablePropertyTypes = async (
  compoundId: number,
  language?: string
) => {
  const END_POINT = getAvailablePropertyTypesEndpoint(compoundId, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getProperty = async (
  slug: string,
  language?: string,
  detail?: string
) => {
  const END_POINT = getPropertyEndpoint(slug, language, detail);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getRecentSearch = async (language?: string) => {
  const END_POINT = await getRecentSearchEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getArea = async (slug: string, language?: string) => {
  const END_POINT = getAreaEndpoint(slug, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getAreaSummary = async (id: number, language?: string) => {
  const END_POINT = getAreaSummaryEndpoint(id);
  return newApi()
    .get(END_POINT, { headers: { 'Accept-Language': language } })
    .then(({ data }) => data);
};

export const getDeveloper = async (slug: string, language?: string) => {
  const END_POINT = getDeveloperEndpoint(slug, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getDevelopers = async (
  with_summary?: boolean,
  language?: string
) => {
  const END_POINT = getDevelopersEndpoint(with_summary, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) =>
      data.map((item: any) => ({
        ...item,
        imageUrl: item.image ?? null,
        image: null
      }))
    );
};

export const getAreas = async (with_summary?: boolean, language?: string) => {
  const END_POINT = getAreasEndpoint(with_summary, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) =>
      data.map((item: any) => ({
        ...item,
        imageUrl: item.image ?? null,
        image: null
      }))
    );
};

export const trackActivity = async (body: any, language?: string) => {
  const END_POINT = getTrackActivityEndpoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};
export const getStatistics = async (language?: string) => {
  const END_POINT = getStatisticsEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getNewLandingPage = async (
  id: string
): Promise<NewTopCompoundsDTO> => {
  const END_POINT = getNewLandingPageEndpoint(id);
  return newApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const submitPropertyMonitorForm = async (
  body: any,
  language?: string
) => {
  const END_POINT = submitPropertyMonitorFormEndpoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getLaunchesAreas = async (language?: string) => {
  const END_POINT = getLaunchesAreasEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getPropertyTypes = async (language?: string) => {
  const END_POINT = getPropertyTypesEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getOffers = async (params: {}, language?: string) => {
  const END_POINT = getOffersEndpoint(language);
  return oldApi()
    .get(END_POINT, { params })
    .then(({ data }) => data);
};

export const getNewOffers = async (body: any, language?: string) => {
  const END_POINT = getNewOffersEndpoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const addResaleData = async (body: any) => {
  const END_POINT = resaleAdditionalDataEndpoint();
  return oldApi().post(END_POINT, body);
};

export const getOffersFilterOptions = async (language?: string) => {
  const END_POINT = getOffersFilterOptionsEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getOffersDevelopers = async (language?: string) => {
  const END_POINT = getOfferDevelopersEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getOffersAreas = async (language?: string) => {
  const END_POINT = getOfferAreasEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getAffordability = async (body: any) => {
  const END_POINT = getAffordabilityEndpoint();
  return newApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getDynamicBanners = async (
  language?: string
): Promise<Banner[]> => {
  const END_POINT = getDynamnicBannersEndpoint();
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};
