import { FAQPage } from 'schema-dts';
import { JsonLd } from './JsonLD';
import { FAQ } from '../../../new-lib/types/common';

function FAQPageSchema(props: { pageProps: any }) {
  const faqs: FAQ[] = props.pageProps?.faqs;

  if (!faqs || faqs.length == 0) return <></>;

  const faqPage: FAQPage = {
    '@type': 'FAQPage',
    mainEntityOfPage: faqs
      .filter(faq => faq?.question && faq?.answer)
      .map(faq => ({
        '@type': 'Question',
        name: faq?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq?.answer
        }
      }))
  };

  return JsonLd<FAQPage>('faq-schema', {
    '@context': 'https://schema.org',
    ...faqPage
  });
}

export default FAQPageSchema;
